import PropTypes from "prop-types"
import React from "react"

/**
 * Internal components
 */
import styles from "../../scss/includes/atoms/checkbox.module.scss"

const Checkbox = ({ title, slug, checked, onChange, onClick }) => {
  return (
    <label className={`control ${styles.control} ${slug}`}>
      <input
        type="checkbox"
        name={slug}
        checked={checked}
        onClick={onClick}
        onChange={onChange}
      />
      <span className={`control-indicator ${styles.indicator}`} />
      <span className={`control-label ${styles.label}`}>{title}</span>
    </label>
  )
}

Checkbox.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
}

export default Checkbox
