import PropTypes from "prop-types"
import React from "react"

/**
 * Internal components
 */
import styles from "../../scss/includes/atoms/tab-panel.module.scss"

const TabPanel = ({ children, slug, hidden = false }) => {
  return (
    <div
      role="tabpanel"
      className={styles.panel}
      tabIndex="0"
      aria-labelledby={`tab-${slug}`}
      hidden={hidden}
    >
      {children}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  slug: PropTypes.string,
  hidden: PropTypes.bool,
}

export default TabPanel
