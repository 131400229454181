import PropTypes from "prop-types"
import React from "react"

import AnchorLink from "react-anchor-link-smooth-scroll"

/**
 * Internal components
 */
import styles from "../../scss/includes/molecules/products.module.scss"
import Container from "./../organisms/Container"
import Section from "./../organisms/Section"
import ParseHTML from "../particles/ParseHTML"
import ArrowDown from "../../images/arrow-down.svg"
import Product from "../atoms/Product"

const Products = ({ content }) => {
  // Offset room for fixed header
  const offset =
    typeof window !== "undefined" && window.innerWidth > 960 ? 150 : 50

  const header = () => {
    if (content.content || content.anchorLinks) {
      return (
        <header className={styles.header}>
          {content.content && (
            <div className={styles.content}>{ParseHTML(content.content)}</div>
          )}
          {content.anchorLinks && (
            <div className={styles.anchorLinks}>
              {content.anchorLinks.map((anchorLink, i) => (
                <AnchorLink
                  key={i}
                  offset={() => offset}
                  href={`#${anchorLink.anchorId
                    .toLowerCase()
                    .replace(/\s/g, "-")}`}
                  className={styles.anchorLink}
                >
                  {anchorLink.title}
                  <ArrowDown />
                </AnchorLink>
              ))}
            </div>
          )}
        </header>
      )
    }
  }

  const width =
    content.maxWidth === "l"
      ? `max-width-l margin-horizontal-auto ${styles.width}`
      : ""

  const products = () => {
    if (content.products) {
      const { products } = content
      return (
        <div className={styles.products}>
          {products.map((product, i) => (
            <Product key={i} product={product} className={styles.product} />
          ))}
        </div>
      )
    }
  }

  return (
    <Section
      className={`products ${styles.section} ${width} section-space-${content.sectionSpacing}`}
      id={content.anchorId}
    >
      <Container className={`max-width-${content.maxWidth}`}>
        {header()}
        {products()}
      </Container>
    </Section>
  )
}

Products.propTypes = {
  content: PropTypes.object,
}

export default Products
