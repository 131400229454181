import PropTypes from "prop-types"
import React from "react"

/**
 * Internal components
 */
import styles from "../../scss/includes/atoms/icon-card.module.scss"

import Button from "../atoms/Button"

const IconCard = ({ icon, preTitle, title, button }) => {
  const header = () => {
    if (icon || preTitle || title) {
      return (
        <header
          className={`card__header ${styles.header}`}
          data-sal="fade"
          data-sal-easing="ease"
          data-sal-duration="750"
          data-sal-delay="500"
        >
          {icon && <img src={icon.mediaItemUrl} alt={icon.altText} />}
          {preTitle && (
            <p className={`card__pretitle p font-weight-semibold`}>
              {preTitle}
            </p>
          )}
          {title && <h3 className={`card__title h3`}>{title}</h3>}
        </header>
      )
    }
  }

  const footer = () => {
    if (button) {
      return (
        <footer
          className={`card__footer ${styles.footer}`}
          data-sal="fade"
          data-sal-easing="ease"
          data-sal-duration="750"
          data-sal-delay="600"
        >
          <Button title={button.title} url={button.url} outline />
        </footer>
      )
    }
  }

  return (
    <article
      className={`card ${styles.card}`}
      data-sal="zoom-out"
      data-sal-easing="ease"
      data-sal-duration="500"
    >
      {header()}
      {footer()}
    </article>
  )
}

IconCard.propTypes = {
  icon: PropTypes.object,
  preTitle: PropTypes.string,
  title: PropTypes.string,
  button: PropTypes.object,
}

export default IconCard
