import React, { useEffect } from "react"

/**
 * Internal components
 */
import Layout from "../particles/Layout"
import PageHead from "../molecules/PageHead"
import Products from "../molecules/Products"
import Cards from "../molecules/Cards"
import Contact from "../molecules/Contact"
import Hero from "../molecules/Hero"
import Resources from "../molecules/Resources"
import ContentAndImage from "../molecules/ContentAndImage"
import FullWidthCTA from "../molecules/FullWidthCTA"
import FullWidthContent from "../molecules/FullWidthContent"
import FindAllergist from "../molecules/FindAllergist"
import FindAllergistLink from "../molecules/FindAllergistLink"
import ResourcesCarousel from "../molecules/ResourcesCarousel"
import ContentAndIcons from "../molecules/ContentAndIcons"
import ContentAndIcon from "../molecules/ContentAndIcon"
import DisclaimerPopup from "../molecules/DisclaimerPopup"

import styles from "../../scss/includes/templates/page.module.scss"

const PageTemplate = props => {
  const { layouts, postType } = props.pageContext

  const flexiContent = (content, key) => {
    switch (content.fieldGroupName) {
      case `${postType}_Flexicontent_FlexiContent_Cards`:
        return <Cards content={content} key={key} />
      case `${postType}_Flexicontent_FlexiContent_Contact`:
        return <Contact content={content} key={key} />
      case `${postType}_Flexicontent_FlexiContent_ContentAndIcons`:
        return <ContentAndIcons content={content} key={key} />
      case `${postType}_Flexicontent_FlexiContent_ContentAndIcon`:
        return <ContentAndIcon content={content} key={key} />
      case `${postType}_Flexicontent_FlexiContent_PageHead`:
        return <PageHead content={content} key={key} />
      case `${postType}_Flexicontent_FlexiContent_Hero`:
        return <Hero content={content} key={key} />
      case `${postType}_Flexicontent_FlexiContent_Products`:
        return <Products content={content} key={key} />
      case `${postType}_Flexicontent_FlexiContent_Resources`:
        return (
          <Resources content={content} location={props.location} key={key} />
        )
      case `${postType}_Flexicontent_FlexiContent_ResourcesCarousel`:
        return <ResourcesCarousel content={content} key={key} />
      case `${postType}_Flexicontent_FlexiContent_ContentAndImage`:
        return <ContentAndImage content={content} key={key} />
      case `${postType}_Flexicontent_FlexiContent_FullwidthCta`:
        return <FullWidthCTA content={content} key={key} />
      case `${postType}_Flexicontent_FlexiContent_FullwidthContent`:
        return <FullWidthContent content={content} key={key} />
      case `${postType}_Flexicontent_FlexiContent_FindAllergist`:
        return (
          <FindAllergist
            content={content}
            location={props.location}
            key={key}
          />
        )
      case `${postType}_Flexicontent_FlexiContent_FindAllergistLink`:
        return <FindAllergistLink content={content} key={key} />
      case `${postType}_Flexicontent_FlexiContent_DisclaimerPopup`:
        return <DisclaimerPopup content={content} key={key} />
      default:
        return null
    }
  }

  // Scroll to top on page change
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [props.pageContext])

  switch (props.path) {
    case "/":
      // Homepage
      const [hero, ...rest] = layouts
      return (
        <Layout context={props.pageContext}>
          <div>{flexiContent(hero, "hero")}</div>
          <div className={styles.section}>{rest.map(flexiContent)}</div>
        </Layout>
      )
    default:
      // All other pages
      const content = layouts.map(flexiContent)
      return <Layout context={props.pageContext}>{content}</Layout>
  }
}

export default PageTemplate
