import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import Swiper from "react-id-swiper"

// Import Swiper styles - NOTE: now @imported in common.scss
//import 'swiper/swiper.scss'

/**
 * Internal components
 */
import Container from "./../organisms/Container"
import Section from "./../organisms/Section"
import { useQueryPosts } from "../particles/hooks/useQueryPosts"
import styles from "../../scss/includes/molecules/carousel.module.scss"
import btnstyles from "../../scss/includes/atoms/button.module.scss"
import ChevronRight from "../../images/chevron-right.svg"
import ChevronLeft from "../../images/chevron-left.svg"
import Chevron from "../../images/chevron.svg"
import Card from "../atoms/Card"
//import Button from '../atoms/Button'

const ResourcesCarousel = ({ content }) => {
  const ref = useRef(null)
  const [prevButton, setPrevButton] = useState(false)
  const [nextButton, setNextButton] = useState(true)

  const postData = useQueryPosts()
  const posts = postData.posts.nodes.slice(0, 8)

  useEffect(() => {
    if (ref.current.swiper.isEnd && ref.current.swiper.isBeginning) {
      setNextButton(false)
    }
  }, [])

  const header = () => {
    if (content.title) {
      return (
        <header
          className={`carousel__header ${styles.header}`}
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="750"
        >
          {content.title && (
            <h2 className={`carousel__title h2`}>{content.title}</h2>
          )}
          {buttons()}
        </header>
      )
    }
  }

  const params = {
    slidesPerView: 1.1,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    spaceBetween: 16,
    breakpointsInverse: true,
    autoplay: true,
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 12,
      },
      968: {
        slidesPerView: 3,
        spaceBetween: 32,
      },
    },
  }

  const next = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideNext()

      if (ref.current.swiper.isEnd) {
        setNextButton(false)
        setPrevButton(true)
      }
      if (ref.current.swiper.isBeginning) {
        setPrevButton(false)
        setNextButton(true)
      }
    }
  }

  const prev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slidePrev()

      if (ref.current.swiper.isEnd) {
        setNextButton(false)
        setPrevButton(true)
      }
      if (ref.current.swiper.isBeginning) {
        setPrevButton(false)
        setNextButton(true)
      }
    }
  }

  const buttons = () => {
    if (content.buttons) {
      return (
        <div className={styles.buttons}>
          {content.buttons.map((button, i) => (
            //<Button key={i} title={button.button.title} url={button.button.url} target={button.button.target} outline primary/>
            <Link
              key={i}
              to={button.button.url}
              state={{ tab: i }}
              className={`button ${btnstyles.button} ${btnstyles.outline} ${btnstyles.primary}`}
            >
              <span className={btnstyles.content}>
                {button.button.title}
                <span className={`icon ${btnstyles.icon}`}>
                  <Chevron />
                </span>
              </span>
            </Link>
          ))}
        </div>
      )
    }
  }

  const button = url => ({
    title: "Read more",
    url: url,
  })

  return (
    <Section
      className={`resources-carousel ${styles.section} section-space-${content.sectionSpacing}`}
      id={content.anchorId}
    >
      <Container className={`max-width-${content.maxWidth}`}>
        {header()}
        <div
          className={`carousel`}
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="750"
          data-sal-delay="300"
        >
          <Swiper {...params} ref={ref}>
            {posts.map((post, i) => {
              // console.log("Posts", post.tags)
              const tags =
                post.tags.nodes && post.tags.nodes.length > 0
                  ? post.tags.nodes[0].name
                  : "Article"
              return (
                <div key={i} className={styles.slide}>
                  <Card
                    title={post.title}
                    image={post.featuredImage}
                    preTitle={tags}
                    button={button(post.uri)}
                  />
                </div>
              )
            })}
          </Swiper>
          <footer
            slot="container-end"
            className={`carousel-footer ${styles.footer}`}
          >
            {buttons()}
            <button
              onClick={prev}
              className={`swiper-button swiper-button-prev ${
                styles.swiperButton
              } ${styles.prev} ${!prevButton ? styles.disabled : ""}`}
            >
              <ChevronLeft />
            </button>
            <button
              onClick={next}
              className={`swiper-button swiper-button-next ${
                styles.swiperButton
              } ${styles.next} ${!nextButton ? styles.disabled : ""}`}
            >
              <ChevronRight />
            </button>
          </footer>
        </div>
      </Container>
    </Section>
  )
}

ResourcesCarousel.propTypes = {
  content: PropTypes.object,
}

export default ResourcesCarousel
