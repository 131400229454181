import PropTypes from "prop-types"
import React from "react"

/**
 * Internal components
 */
import styles from "../../scss/includes/molecules/pagination.module.scss"
import ChevronRight from "../../images/chevron-right.svg"
import ChevronLeft from "../../images/chevron-left.svg"

const Pagination = ({ next, prev, nextOnClick, prevOnClick }) => {
  const isDisabled = button => {
    if (!button) {
      return styles.disabled
    }
  }

  return (
    <nav className={`pagination ${styles.pagination}`}>
      <button
        className={`${styles.button} ${isDisabled(prev)}`}
        onClick={prevOnClick}
      >
        <ChevronLeft />
      </button>
      <button
        className={`${styles.button} ${isDisabled(next)}`}
        onClick={nextOnClick}
      >
        <ChevronRight />
      </button>
    </nav>
  )
}

Pagination.propTypes = {
  next: PropTypes.bool,
  prev: PropTypes.bool,
  nextOnClick: PropTypes.func,
  prevOnClick: PropTypes.func,
}

export default Pagination
