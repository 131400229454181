import { useStaticQuery, graphql } from "gatsby"

export const useQueryTaxonomies = () => {
  const { wordpress } = useStaticQuery(
    graphql`
      query WordPessPostsTaxonomies {
        wordpress {
          categories(where: { exclude: "Uncategorised", hideEmpty: true }) {
            nodes {
              databaseId
              name
              slug
            }
          }
          tags(where: { hideEmpty: true }) {
            nodes {
              databaseId
              name
              slug
            }
          }
        }
      }
    `
  )

  return wordpress
}
