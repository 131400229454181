import PropTypes from "prop-types"
import React from "react"
import Container from "./../organisms/Container"
import Section from "./../organisms/Section"

/**
 * Internal components
 */
import styles from "../../scss/includes/molecules/contact.module.scss"
import { useSiteOptionsData } from "../particles/hooks/useSiteOptionsData"
import Columns from "../organisms/Columns"
import Column from "../atoms/Column"
import ParseHTML from "../particles/ParseHTML"
import { useSiteSocialData } from "../particles/hooks/useSiteSocialData"
import ContactIcon from "../atoms/ContactIcon"
import ContactForm from "../atoms/ContactForm"

const Contact = ({ content }) => {
  const optionsData = useSiteOptionsData()
  const social = useSiteSocialData()

  const options = optionsData[0].options
  const phone = options.phone
  const email = options.email
  const linkedIn = social.linkedIn.url
  const contactEndpoint = options.contact_endpoint

  const header = () => {
    if (content.title || content.content) {
      return (
        <header className={`contact__header ${styles.header}`}>
          {content.title && (
            <h3 className={`contact__title h3`}>{content.title}</h3>
          )}
          {content.content && (
            <div className={`contact__content`}>
              {ParseHTML(content.content)}
            </div>
          )}
          <ContactForm endpoint={contactEndpoint} />
        </header>
      )
    }
  }

  const sidebarHeader = () => {
    if (content.sidebarTitle) {
      return (
        <header className={`contact__header ${styles.header}`}>
          {content.sidebarTitle && (
            <h3 className={`contact__title h3`}>{content.sidebarTitle}</h3>
          )}
        </header>
      )
    }
  }

  return (
    <Section
      className={`contact ${styles.section} section-space-${content.sectionSpacing}`}
      id={content.anchorId}
    >
      <Container className={`max-width-${content.maxWidth}`}>
        <Columns columns={"50"}>
          <Column>{header()}</Column>
          <Column className={styles.sidebar}>
            {sidebarHeader()}
            <ul className={styles.icons}>
              {phone && (
                <ContactIcon
                  url={`tel:${phone}`}
                  icon={"phone"}
                  title={phone}
                />
              )}
              {email && (
                <ContactIcon
                  url={`mailto:${email}`}
                  icon={"mail"}
                  title={email}
                />
              )}
              {linkedIn && (
                <ContactIcon
                  url={linkedIn}
                  icon={"linkedin"}
                  title="LinkedIn"
                />
              )}
              {content.sidebarContent && (
                <ContactIcon icon={"phone"} content={content.sidebarContent} />
              )}
            </ul>
          </Column>
        </Columns>
      </Container>
    </Section>
  )
}

Contact.propTypes = {
  content: PropTypes.object,
}

export default Contact
