import PropTypes from "prop-types"
import React from "react"
import Container from "./../organisms/Container"
import Section from "./../organisms/Section"
import Img from "gatsby-image"

/**
 * Internal components
 */
import styles from "../../scss/includes/molecules/content-and-icon.module.scss"
import ParseHTML from "../particles/ParseHTML"
import Button from "../atoms/Button"

const ContentAndIcon = ({ content }) => {
  const image = () => {
    if (content.icon) {
      let imageSrc
      if (content.icon.imageFile.childImageSharp !== null) {
        imageSrc = content.icon.imageFile.childImageSharp.fluid
      } else {
        imageSrc = {
          src: content.icon.sourceUrl,
          sizes: content.icon.sizes,
          srcSet: content.icon.srcSet,
        }
      }

      return (
        <Img
          className={styles.icon}
          objectFit="cover"
          objectPosition="50% 50%"
          alt={content.icon.altText}
          sizes={{
            ...content.icon.sizes,
            ...imageSrc,
            aspectRatio: 114 / 114,
            objectFit: "cover",
            objectPosition: "50% 50%",
          }}
        />
      )
    }
  }

  return (
    <Section
      className={`content-and-icon ${styles.section} media-${content.mediaSide} section-space-${content.sectionSpacing}`}
      id={content.anchorId}
    >
      <Container
        className={`max-width-${content.maxWidth} ${styles.container}`}
      >
        <div
          className={styles.imageWrapper}
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="750"
          data-sal-delay="100"
        >
          {image()}
          {content.title && (
            <h2 className={`h2 ${styles.title}`}>{content.title}</h2>
          )}
          {content.button && (
            <Button
              className={styles.button}
              title={content.button.title}
              url={content.button.url}
              target={content.button.target}
              primary
              outline
            />
          )}
        </div>
        <div
          className={styles.contentWrapper}
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="750"
        >
          {content.content && (
            <div className="content">{ParseHTML(content.content)}</div>
          )}
          {content.button && (
            <Button
              className={styles.button}
              title={content.button.title}
              url={content.button.url}
              target={content.button.target}
              primary
              outline
            />
          )}
        </div>
      </Container>
    </Section>
  )
}

ContentAndIcon.propTypes = {
  content: PropTypes.object,
}

export default ContentAndIcon
