import PropTypes from "prop-types"
import React, { useState } from "react"
import axios from "axios"

/**
 * Internal components
 */
import styles from "../../scss/includes/atoms/contact-form.module.scss"
import btnstyles from "../../scss/includes/atoms/button.module.scss"
import Chevron from "../../images/chevron.svg"
//import ParseHTML from '../particles/ParseHTML'

const ContactForm = ({ endpoint }) => {
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    honey: "",
  })
  const [state, setState] = useState({
    submitting: false,
    sent: false,
    buttonHtml: "Submit",
  })

  const handleInputChange = event => {
    const target = event.target
    setContactData({
      ...contactData,
      [target.name]: target.value,
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    // console.log("onSubmit", endpoint, state, contactData)
    if (!endpoint) return false
    if (state.submitting) return false
    if (contactData.honey) return false
    if (
      contactData.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) === null
    ) {
      setState({ ...state, buttonHtml: "Invalid Email" })
      setTimeout(() => {
        setState({ ...state, buttonHtml: "Send" })
      }, 2000)
      return false
    }
    setState({ ...state, submitting: true, buttonHtml: "Sending" })

    axios({
      method: "POST",
      url: endpoint,
      timeout: 15000,
      data: contactData,
    }).then(res => {
      if (res.status === 200) {
        setState({
          submitting: true,
          sent: true,
          buttonHtml: "Sent!",
        })
      } else {
        setState({
          submitting: false,
          sent: false,
          buttonHtml: "Submit",
        })
      }
    })
  }

  return (
    <form
      className={`contact-form ${styles.contactForm}`}
      onSubmit={handleSubmit}
    >
      <div className="contact-form__item">
        <label for="name">Name</label>
        <input
          id="name"
          name="name"
          type="text"
          value={contactData.name}
          onChange={handleInputChange}
        />
      </div>
      <div className="contact-form__item">
        <label for="email">Email</label>
        <input
          id="email"
          name="email"
          type="email"
          value={contactData.email}
          onChange={handleInputChange}
        />
      </div>
      <div className="contact-form__item">
        <label for="phone">Phone</label>
        <input
          id="phone"
          name="phone"
          type="text"
          value={contactData.phone}
          onChange={handleInputChange}
        />
      </div>
      <div className="contact-form__item">
        <label for="message">Message</label>
        <textarea
          id="message"
          name="message"
          rows="3"
          value={contactData.message}
          onChange={handleInputChange}
        ></textarea>
      </div>
      <div className="contact-form__honey">
        <label for="honey">Honey</label>
        <input
          id="honey"
          name="honey"
          type="text"
          value={contactData.honey}
          onChange={handleInputChange}
        />
      </div>
      <div className="contact-form__foot">
        <button
          className={`button ${btnstyles.button} ${btnstyles.outline} ${btnstyles.primary}`}
        >
          <span className={btnstyles.content}>
            {state.buttonHtml}
            <span className={`icon ${btnstyles.icon}`}>
              <Chevron />
            </span>
          </span>
        </button>
      </div>
    </form>
  )
}

ContactForm.propTypes = {
  endpoint: PropTypes.string,
}

export default ContactForm
