import PropTypes from "prop-types"
import React, { useState, useRef } from "react"
import { InView } from "react-intersection-observer"
import Container from "./../organisms/Container"
import Section from "./../organisms/Section"
/**
 * Internal components
 */
import Arrow from "../../images/arrow-down.svg"
import useSplitLines from "../particles/hooks/useSplitLines"
import useWindowSize from "../particles/hooks/useWindowSize"
import useAnimationFrame from "../particles/hooks/useAnimationFrame"

// Styles
import styles from "../../scss/includes/molecules/hero.module.scss"
import containerStyles from "../../scss/includes/organisms/container.module.scss"

// Parallax constants
const PARALLAX_SPEED = 0.1
const PARALLAX_ELASTICITY = 0.8
const EPSILON = 0.1

const Hero = ({ content }) => {
  const ssr = typeof window === "undefined"
  const { title, firstpanel, secondpanel, image } = content
  const { height } = useWindowSize()

  // Panels
  const measure = useRef(null)
  const showPanel = p => p.content.length > 0
  const usePanel = (content, Tag = "p", tagClass) => {
    const lines = useSplitLines(content, measure, Tag, tagClass)
    return { content: lines, Tag, tagClass }
  }
  const panels = [
    usePanel(title, "h1", "h1"),
    usePanel(firstpanel),
    usePanel(secondpanel),
  ]
  const numPanels = panels.reduce((acc, p) => {
    return acc + showPanel(p) ? 1 : 0
  }, 0)

  // Arrow visibility, background parallax
  const [arrowOpacity, setArrowOpacity] = useState(1)
  const [backgroundY, setBackgroundY] = useState(0)
  useAnimationFrame(dt => {
    const scrollY = ssr ? 0 : document.scrollingElement.scrollTop
    setArrowOpacity(scrollY > height * (numPanels - 0.4) ? 0 : 1)
    setBackgroundY(y => {
      const target = scrollY * PARALLAX_SPEED
      const dy = target - y
      if (Math.abs(dy) <= EPSILON) return target
      else return y + (dy * PARALLAX_ELASTICITY) / (dt || 1)
    })
  })

  // Track screen visibility of each panel
  const [visibilities, setVisibilities] = useState([true, false, false])
  const setVisibility = (index, visible) =>
    setVisibilities(arr => {
      return arr.map((current, i) => (i === index ? visible : current))
    })

  const clsContainer = `max-width-${content.maxWidth}`
  const clsHero = [
    "hero",
    styles.section,
    `section-space-${content.sectionSpacing}`,
  ].join(" ")
  const clsMeasure = [
    clsContainer,
    styles.panelMeasure,
    styles.panelContent,
    containerStyles.container,
  ].join(" ")

  // Reusable panel scaffold
  const panel = ({ Tag, tagClass = "", content }, index) => {
    // Determine classes
    const cls = [styles.panelContent]
    const firstVisible = visibilities.findIndex(Boolean)
    if (index !== firstVisible) {
      if (firstVisible === -1) cls.push(styles.panelAbove)
      else
        cls.push(index < firstVisible ? styles.panelAbove : styles.panelBelow)
    } else {
      cls.push(styles.panelActive)
    }

    // Render
    return (
      <InView
        as="div"
        key={index}
        threshold="0.5"
        className={styles.panel}
        onChange={visible => setVisibility(index, visible)}
      >
        <div className={cls.join(" ")}>
          <Container className={clsContainer}>
            <Tag className={tagClass}>
              {content.map((text, i) => (
                <span key={text + "_" + i}>{text}</span>
              ))}
            </Tag>
          </Container>
        </div>
      </InView>
    )
  }

  // JS styles
  const backgroundStyle = {
    backgroundImage: `url(${image.sourceUrl})`,
    transform: `translate3d(0, -${backgroundY}px, 0)`,
  }
  const arrowStyle = {
    position: "fixed",
    transition: "all 200ms ease-in",
    opacity: arrowOpacity,
  }

  return (
    <Section
      id={content.anchorId}
      className={clsHero}
      innerClass={styles.scrollingArea}
    >
      {image && <div className={styles.image} style={backgroundStyle} />}
      <Arrow className={`animate-bob ${styles.arrow}`} style={arrowStyle} />
      {/* Invisible fake hero panel for measuring text */}
      <div ref={measure} className={clsMeasure}></div>
      {/* Actual hero panels */}
      {panels.filter(showPanel).map(panel)}
    </Section>
  )
}

Hero.propTypes = {
  content: PropTypes.object,
}

export default Hero
