import PropTypes from "prop-types"
import React from "react"
import Container from "./../organisms/Container"
import Section from "./../organisms/Section"
import Img from "gatsby-image"

/**
 * Internal components
 */
import styles from "../../scss/includes/molecules/content-and-image.module.scss"
import ParseHTML from "../particles/ParseHTML"
import Button from "../atoms/Button"

const ContentAndImage = ({ content }) => {
  //console.log( 'ContentAndImage.content', content )

  const image = () => {
    if (content.image) {
      let imageSrc
      if (content.image.imageFile.childImageSharp !== null) {
        imageSrc = content.image.imageFile.childImageSharp.fluid
      } else {
        imageSrc = {
          src: content.image.sourceUrl,
          sizes: content.image.sizes,
          srcSet: content.image.srcSet,
        }
      }
      return (
        <div
          className={`${styles.imageWrapper} ${content.imageStyle}`}
          data-sal="zoom-in"
          data-sal-easing="ease"
          data-sal-duration="750"
          data-sal-delay="750"
        >
          <Img
            className={styles.image}
            alt={content.image.altText}
            sizes={{
              ...content.image.sizes,
              ...imageSrc,
              objectFit: "cover",
              objectPosition: "50% 50%",
            }}
            objectFit="cover"
            objectPosition="50% 50%"
          />
        </div>
      )
    }
  }

  return (
    <Section
      className={`content-and-image ${styles.section} media-${content.mediaSide} section-space-${content.sectionSpacing}`}
      id={content.anchorId}
    >
      <Container
        className={`max-width-${content.maxWidth} ${styles.container}`}
      >
        {image()}
        <div
          className={styles.contentWrapper}
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-delay="500"
          data-sal-duration="750"
        >
          {content.title && (
            <h2 className={`h2 ${styles.title}`}>{content.title}</h2>
          )}
          {content.content && (
            <div className="content">{ParseHTML(content.content)}</div>
          )}
          {content.button && (
            <Button
              title={content.button.title}
              url={content.button.url}
              target={content.button.target}
              outline
            />
          )}
        </div>
      </Container>
    </Section>
  )
}

ContentAndImage.propTypes = {
  content: PropTypes.object,
}

export default ContentAndImage
