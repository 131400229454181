import PropTypes from "prop-types"
import React from "react"
import { navigate, Link } from "gatsby"
import Container from "./../organisms/Container"
import Section from "./../organisms/Section"

/**
 * Internal components
 */
import styles from "../../scss/includes/molecules/find-allergist-link.module.scss"
import btnstyles from "../../scss/includes/atoms/button.module.scss"
import ParseHTML from "../particles/ParseHTML"
import { useSiteOptionsData } from "../particles/hooks/useSiteOptionsData"
import Chevron from "../../images/chevron.svg"
import SearchIcon from "../../images/search-circle.svg"
import useRelative from "../particles/hooks/useRelative"

const FindAllergistLink = ({ content }) => {
  const optionsData = useSiteOptionsData()
  const allergistFinderUrl = useRelative(
    optionsData[0].options.allergist_finder
  )
  let searchStr = ""

  const canGetCurrentLocation = () => {
    return typeof window !== "undefined" && "geolocation" in window.navigator
  }

  /**
   * Actions
   */
  const handleInputChange = event => {
    event.preventDefault()
    searchStr = event.target.value
    if (event.which === 13) {
      navigate(allergistFinderUrl, { state: { q: searchStr } })
    }
  }

  const handleInputClick = event => {
    event.preventDefault()
    const searchStr = document.getElementById("location-input").value
    navigate(allergistFinderUrl, { state: { q: searchStr } })
  }

  //console.log(content);

  const header = () => {
    if (content.icon || content.title || content.content) {
      return (
        <header
          className={`find-allergist-link__header ${styles.header}`}
          data-sal="fade"
          data-sal-easing="ease"
          data-sal-duration="750"
          data-sal-delay="500"
        >
          {content.icon && (
            <img src={content.icon.mediaItemUrl} alt={content.icon.altText} />
          )}
          {content.title && (
            <h3 className={`find-allergist-link__title h3`}>{content.title}</h3>
          )}
          {content.content && (
            <div className={`find-allergist-link__content`}>
              {ParseHTML(content.content)}
            </div>
          )}
        </header>
      )
    }
  }

  const footer = () => {
    if (allergistFinderUrl) {
      return (
        <footer
          className={`find-allergist-link__footer ${styles.footer}`}
          data-sal="fade"
          data-sal-easing="ease"
          data-sal-duration="750"
          data-sal-delay="600"
        >
          <div className={styles.inputWrapper}>
            <input
              type="text"
              id="location-input"
              onKeyUp={event => handleInputChange(event)}
              placeholder="Postcode or Suburb"
            />
            <SearchIcon
              className={styles.inputIcon}
              onClick={handleInputClick}
            />
          </div>
          {canGetCurrentLocation() && (
            <Link
              to={allergistFinderUrl}
              state={{ cl: true }}
              className={`button ${btnstyles.button} ${btnstyles.outline} ${btnstyles.light}`}
            >
              <span className={btnstyles.content}>
                Use current location
                <span className={`icon ${btnstyles.icon}`}>
                  <Chevron />
                </span>
              </span>
            </Link>
          )}
        </footer>
      )
    }
  }

  return (
    <Section
      className={`find-allergist-link ${styles.section} section-space-${content.sectionSpacing}`}
      id={content.anchorId}
    >
      <Container className={`max-width-${content.maxWidth}`}>
        <div
          className={styles.containerInner}
          data-sal="zoom-out"
          data-sal-easing="ease"
          data-sal-duration="750"
        >
          {header()}
          {footer()}
        </div>
      </Container>
    </Section>
  )
}

FindAllergistLink.propTypes = {
  content: PropTypes.object,
}

export default FindAllergistLink
