import { useStaticQuery, graphql } from "gatsby"

export const useQueryPosts = () => {
  const { wordpress } = useStaticQuery(
    graphql`
      query WordPessPostsPage {
        wordpress {
          posts(
            first: 8
            where: { orderby: { field: DATE, order: DESC }, status: PUBLISH }
          ) {
            pageInfo {
              hasPreviousPage
              startCursor
              hasNextPage
              endCursor
            }
            nodes {
              id
              databaseId
              date
              status
              title
              uri
              featuredImage {
                node {
                  altText
                  mediaItemUrl
                  uri
                  sizes(size: CARD)
                  imageFile {
                    childImageSharp {
                      fluid {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                      }
                    }
                  }
                }
              }
              categories {
                nodes {
                  name
                  slug
                  termTaxonomyId
                  parentId
                }
              }
              tags {
                nodes {
                  termTaxonomyId
                  slug
                  id
                  name
                  count
                }
              }
            }
          }
        }
      }
    `
  )

  return wordpress
}
