import React, { useEffect, useState } from "react"

/**
 * Internal components
 */
import styles from "../../scss/includes/molecules/posts.module.scss"
import Card from "../atoms/Card"
import { useQueryTaxonomies } from "../particles/hooks/useQueryTaxonomies"
import Checkbox from "../atoms/Checkbox"
import Pagination from "./Pagination"

const PostList = ({
  data,
  error,
  loading,
  fetchMore,
  handleInputChange,
  checkedItems,
}) => {
  const taxonomiesData = useQueryTaxonomies()
  const tagsData = taxonomiesData.tags.nodes

  const [posts, setPosts] = useState(null)

  useEffect(() => {
    if (data && data.posts) {
      setPosts(data.posts)
    }
  }, [data])

  // Function to update the query with the new results
  const updateQuery = (previousResult, { fetchMoreResult }) => {
    return fetchMoreResult.posts.edges.length ? fetchMoreResult : previousResult
  }

  const tags = () => {
    return tagsData.map((tag, i) => (
      <Checkbox
        key={i}
        slug={tag.slug}
        title={tag.name}
        onChange={handleInputChange}
        checked={checkedItems[tag.slug]}
      />
    ))
  }

  const header = () => {
    return (
      <header className={styles.header}>
        <div>
          <h4 className="h4">Filter resources by</h4>
        </div>
        <div className={styles.filters}>{tags()}</div>
      </header>
    )
  }

  const button = url => ({
    title: "Read more",
    url: url,
  })

  return (
    <div>
      {header()}
      {posts && posts.edges ? (
        <div>
          <div className={`posts-wrapper ${styles.posts}`}>
            {posts.edges.map((post, i) => {
              const tags =
                post.node.tags && post.node.tags.nodes.length > 0
                  ? post.node.tags.nodes[0].name
                  : "Article"
              return (
                <div key={i}>
                  <Card
                    preTitle={tags}
                    image={post.node.featuredImage}
                    title={post.node.title}
                    button={button(post.node.uri)}
                  />
                </div>
              )
            })}
          </div>
          <Pagination
            next={posts.pageInfo.hasNextPage}
            prev={posts.pageInfo.hasPreviousPage}
            nextOnClick={() => {
              fetchMore({
                variables: {
                  first: 9,
                  after: posts.pageInfo.endCursor || null,
                  last: null,
                  before: null,
                },
                updateQuery,
              })
            }}
            prevOnClick={() => {
              fetchMore({
                variables: {
                  first: null,
                  after: null,
                  last: 9,
                  before: posts.pageInfo.startCursor || null,
                },
                updateQuery,
              })
            }}
          />
        </div>
      ) : (
        <div>No posts were found...</div>
      )}
    </div>
  )
}

export default PostList
