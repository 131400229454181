import PropTypes from "prop-types"
import React, { useState } from "react"
import gql from "graphql-tag"
import { useQuery } from "react-apollo-hooks"

/**
 * Internal components
 */
import PostList from "./PostList"
import { useQueryTaxonomies } from "../particles/hooks/useQueryTaxonomies"

export const PostsQuery = gql`
  query GET_PAGINATED_POSTS(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $category: String
    $tag: [String]
  ) {
    posts(
      first: $first
      last: $last
      after: $after
      before: $before
      where: { categoryName: $category, tagSlugIn: $tag }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          databaseId
          date
          status
          title
          uri
          featuredImage {
            node {
              altText
              mediaItemUrl
              sourceUrl
              uri
              sizes(size: CARD)
              srcSet(size: CARD)
            }
          }
          tags {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`
const Post = ({ category, tag }) => {
  const taxonomiesData = useQueryTaxonomies()
  const tagsData = taxonomiesData.tags.nodes
  const checkBoxes = tagsData.reduce(
    (acc, cur) => ({ ...acc, [cur.slug]: true }),
    {}
  )
  const [checkedItems, setCheckedItems] = useState(checkBoxes)
  const items = checkedItems
  const keys = Object.keys(items)

  const filter = keys.filter(key => {
    return items[key]
  })

  const quotedAndCommaSeparated =
    filter.length > 0 ? '["' + filter.join('","') + '"]' : null

  const variables = {
    first: 9,
    last: null,
    after: null,
    before: null,
    category: category,
    tag:
      quotedAndCommaSeparated === '["article","study"]'
        ? ["article", "study"]
        : quotedAndCommaSeparated,
  }

  const { data, error, loading, fetchMore } = useQuery(PostsQuery, {
    variables,
  })
  if (error) {
    return <pre>{JSON.stringify(error)}</pre>
  }
  const handleInputChange = event => {
    // console.log(quotedAndCommaSeparated === '["article","study"]')
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    })
  }

  return (
    <PostList
      error={error}
      loading={loading}
      data={data}
      fetchMore={fetchMore}
      handleInputChange={handleInputChange}
      checkedItems={checkedItems}
      category={category}
      tag={tag}
    />
  )
}
Post.propTypes = {
  category: PropTypes.string.isRequired,
}

export default Post
