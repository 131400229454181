import PropTypes from "prop-types"
import React from "react"

/**
 * Internal components
 */
import styles from "../../scss/includes/atoms/button.module.scss"
import Chevron from "../../images/chevron.svg"
import useRelative from "../particles/hooks/useRelative"

const Button = ({
  title,
  url,
  target,
  icon = "chevron",
  outline,
  className,
  primary,
  light,
}) => {
  switch (icon.toLowerCase()) {
    default:
      icon = <Chevron />
  }

  // @TODO clean this mess upx
  let classes = " " + className

  if (outline) {
    classes = classes + " button-outline " + styles.outline
  }

  if (primary) {
    classes = classes + " button-primary " + styles.primary
  }

  if (light) {
    classes = classes + " button-white " + styles.light
  }

  return (
    <a
      href={useRelative(url)}
      className={`button ${styles.button}${classes}`}
      target={target ? target : "_self"}
      rel={target && target === "_blank" ? "noreferrer" : ""}
    >
      <span className={styles.content}>
        {title}
        <span className={`icon ${styles.icon}`}>{icon}</span>
      </span>
    </a>
  )
}

Button.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  target: PropTypes.string,
  outline: PropTypes.bool,
  primary: PropTypes.bool,
  light: PropTypes.bool,
  className: PropTypes.string,
}

export default Button
