import PropTypes from "prop-types"
import React from "react"

/**
 * Internal components
 */
import styles from "../../scss/includes/atoms/column.module.scss"

const Column = ({ children, className }) => {
  return (
    <div className={`column ${styles.column} ${className}`}>
      <div className={styles.inner}>{children}</div>
    </div>
  )
}

Column.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Column
