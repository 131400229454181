import PropTypes from "prop-types"
import React from "react"

/**
 * Internal components
 */
import styles from "../../scss/includes/atoms/tab-button.module.scss"

const TabButton = ({ title, slug, onClick, selected = false, index = 0 }) => {
  if (selected) {
    index = -1
  }
  return (
    <button
      role="tab"
      className={styles.button}
      aria-selected={selected}
      aria-controls={`panel-${slug}`}
      id={slug}
      tabIndex={index}
      data-name={slug}
      onClick={onClick}
    >
      {title}
    </button>
  )
}

TabButton.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  index: PropTypes.number,
}

export default TabButton
