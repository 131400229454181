import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image/withIEPolyfill"
/**
 * Internal components
 */
import styles from "../../scss/includes/atoms/card.module.scss"
import PageLink from "./PageLink"
import IconBook from "../../images/book.svg"
import IconUser from "../../images/user.svg"

const Card = ({ image, preTitle, title, button }) => {
  const header = () => {
    if (image) {
      let imageSrc
      if (image.node.imageFile) {
        imageSrc = image.node.imageFile.childImageSharp.fluid
      } else {
        imageSrc = {
          src: image.node.sourceUrl,
          sizes: image.node.sizes,
          srcSet: image.node.srcSet,
        }
      }

      //console.log(imageSrc)
      return (
        <Img
          className={styles.image}
          objectFit="cover"
          objectPosition="50% 50%"
          alt={image.node.altText}
          sizes={{
            ...image.node.sizes,
            ...imageSrc,
            aspectRatio: 768 / 432,
            objectFit: "cover",
            objectPosition: "50% 50%",
          }}
        />
      )
    }
  }

  const icon = preTitle => {
    let icon = ""
    switch (preTitle.toLowerCase()) {
      case "article":
        icon = <IconBook className="icon icon-book" />
        break
      case "study":
        icon = <IconUser className="icon icon-user" />
        break
      default:
        icon = ""
    }
    return icon
  }

  const content = () => {
    if (preTitle || title || button) {
      // @TODO Add article type icon
      return (
        <div className={`card__content ${styles.content}`}>
          {preTitle && (
            <p className={`card__pretitle p font-weight-bold`}>
              {icon(preTitle)}
              {preTitle}
            </p>
          )}
          {title && <h3 className={`card__title h3`}>{title}</h3>}
          {footer()}
        </div>
      )
    }
  }

  const footer = () => {
    if (button) {
      return (
        <footer className={`card__footer ${styles.footer}`}>
          <PageLink title={button.title} url={button.url} />
        </footer>
      )
    }
  }

  return (
    <article className={`card ${styles.card}`}>
      {header()}
      {content()}
    </article>
  )
}

Card.propTypes = {
  image: PropTypes.object,
  preTitle: PropTypes.string,
  title: PropTypes.string,
  button: PropTypes.object,
}

export default Card
