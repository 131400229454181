import PropTypes from "prop-types"
import React, { useState } from "react"

/**
 * Internal components
 */
import Container from "./../organisms/Container"
import Section from "./../organisms/Section"
import styles from "../../scss/includes/molecules/resources.module.scss"
import Tabs from "./Tabs"
import { useQueryTaxonomies } from "../particles/hooks/useQueryTaxonomies"
import TabPanel from "../atoms/TabPanel"
import TabButton from "../atoms/TabButton"
import Posts from "./Posts"

const Resources = ({ content, location }) => {
  const startTab = location.state && location.state.tab ? location.state.tab : 0
  const [tabIndex, setTabIndex] = useState(startTab)
  const taxonomiesData = useQueryTaxonomies()
  const categoriesData = taxonomiesData.categories.nodes

  return (
    <Section
      className={`resources ${styles.section} section-space-${content.sectionSpacing}`}
      id={content.anchorId}
    >
      <Container
        className={`max-width-${content.maxWidth} ${styles.container}`}
      >
        <Tabs>
          <header className={`tabs-header`} role="tablist">
            {categoriesData.map((tab, i) => {
              return (
                <TabButton
                  key={i}
                  slug={tab.slug}
                  title={tab.name}
                  onClick={() => setTabIndex(i)}
                  selected={i === tabIndex}
                />
              )
            })}
          </header>
          {categoriesData.map((tab, i) => {
            return (
              <TabPanel key={i} slug={tab.slug} hidden={i !== tabIndex}>
                <Posts category={tab.name} />
              </TabPanel>
            )
          })}
        </Tabs>
      </Container>
    </Section>
  )
}

Resources.propTypes = {
  content: PropTypes.object,
}

export default Resources
