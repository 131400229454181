import PropTypes from "prop-types"
import React from "react"
import Container from "./../organisms/Container"
import Section from "./../organisms/Section"

/**
 * Internal components
 */
import styles from "../../scss/includes/molecules/cards.module.scss"
import IconCard from "../atoms/IconCard"

const Cards = ({ content }) => {
  const header = () => {
    if (content.title) {
      return (
        <header
          className={`cards__header ${styles.header}`}
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="750"
        >
          {content.title && (
            <h2 className={`cards__title h2`}>{content.title}</h2>
          )}
        </header>
      )
    }
  }

  const cards = () => {
    if (content.cards) {
      const cards = content.cards
      //console.log(cards)
      return (
        <div className={`cards ${styles.cards}`}>
          {cards.map((card, i) => {
            //const animation = i % 2 === 0 ? 'slide-right' : 'slide-left'
            return (
              <IconCard
                key={i}
                icon={card.icon}
                preTitle={card.pretitle}
                title={card.title}
                button={card.link}
              />
            )
          })}
        </div>
      )
    }
  }

  return (
    <Section
      className={`cards-section ${styles.section} section-space-${content.sectionSpacing}`}
      id={content.anchorId}
    >
      <Container className={`max-width-${content.maxWidth}`}>
        {header()}
        {cards()}
      </Container>
    </Section>
  )
}

Cards.propTypes = {
  content: PropTypes.object,
}

export default Cards
