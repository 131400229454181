import { useStaticQuery, graphql } from "gatsby"
// @TODO add feature image
export const useQueryAllergists = () => {
  const { wordpress } = useStaticQuery(
    graphql`
      query allAllergists {
        wordpress {
          allergists(first: 1500) {
            nodes {
              id
              databaseId
              title
              uri
              acf {
                practice
                address1
                address2
                bulkBill
                featured
                city
                country
                email
                lat
                lng
                phone
                services
                state
                web
                postcode
              }
            }
          }
        }
      }
    `
  )

  return wordpress.allergists.nodes
}
