import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//import { navigate, Link } from 'gatsby'
//import Container from './../organisms/Container'
//import Section from './../organisms/Section'
import { useCookies } from "react-cookie"

/**
 * Internal components
 */
import styles from "../../scss/includes/molecules/disclaimer-popup.module.scss"
import btnstyles from "../../scss/includes/atoms/button.module.scss"
import ParseHTML from "../particles/ParseHTML"
import { useSiteOptionsData } from "../particles/hooks/useSiteOptionsData"
//import useRelative from '../particles/hooks/useRelative'

const DisclaimerPopup = ({ content }) => {
  const optionsData = useSiteOptionsData()[0].options
  const sitewideContent = optionsData.disclaimer_content
  const cookieValue = optionsData.disclaimer_cookie_value
  const [cookies, setCookie] = useCookies(["gamma"])
  const cookieContent = cookies.gamma

  const exp = Number(optionsData.disclaimer_cookie_expires)
  const cookieExpires = isNaN(exp) ? 0 : exp

  const [showChallenge, setShowChallenge] = useState(false)

  /**
   * Actions
   */
  const handleYesClick = event => {
    event.preventDefault()
    let d = new Date()
    d.setTime(d.getTime() + cookieExpires * 24 * 60 * 60 * 1000) // in milliseconds
    setCookie("gamma", cookieValue, {
      path: "/",
      expires: d,
      sameSite: true,
    })
    setShowChallenge(false)
  }
  useEffect(() => {
    if (cookieContent === undefined) {
      setShowChallenge(true)
    }
  }, [cookieContent])

  if (showChallenge && cookieContent !== cookieValue) {
    return (
      <div className={styles.disclaimerPopup}>
        <div className={styles.disclaimerPopupInner}>
          {content.localContent && (
            <div className={styles.disclaimerPopupContent}>
              {ParseHTML(content.content)}
            </div>
          )}
          {!content.localContent && (
            <div className={styles.disclaimerPopupContent}>
              {ParseHTML(sitewideContent)}
            </div>
          )}
          <div className={styles.disclaimerPopupActions}>
            <a
              href="#"
              onClick={handleYesClick}
              className={`button ${btnstyles.button} ${btnstyles.primary}`}
            >
              <span className={btnstyles.content}>Yes, I agree</span>
            </a>
            <a
              href="/"
              className={`button ${btnstyles.button} ${btnstyles.primary}`}
            >
              <span className={btnstyles.content}>No, I disagree</span>
            </a>
          </div>
        </div>
      </div>
    )
  } else {
    // console.log("cookie", cookieContent)
    return ""
  }
}

DisclaimerPopup.propTypes = {
  content: PropTypes.object,
}

export default DisclaimerPopup
