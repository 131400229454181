import PropTypes from "prop-types"
import React from "react"

/**
 * Internal components
 */
import styles from "../../scss/includes/organisms/columns.module.scss"

const Columns = ({ children, columns = "50" }) => {
  const layout = "col" + columns

  return <div className={`${styles.columns} ${styles[layout]}`}>{children}</div>
}

Columns.propTypes = {
  children: PropTypes.node.isRequired,
  columns: PropTypes.string,
}

export default Columns
