import { useRef, useCallback, useEffect } from "react"

export default function useAnimationFrame(callback) {
  const ssr = typeof window === "undefined"
  let frame = useRef()
  let lastTimestamp = useRef()

  const animate = useCallback(
    timestamp => {
      if (lastTimestamp.current) {
        const dt = timestamp - lastTimestamp.current
        callback(dt)
      }
      lastTimestamp.current = timestamp
      frame.current = requestAnimationFrame(animate)
    },
    [callback]
  )

  useEffect(() => {
    if (ssr) {
      callback(0)
    } else {
      frame.current = requestAnimationFrame(animate)
      return () => cancelAnimationFrame(frame.current)
    }
  }, [ssr, animate, callback])
}
