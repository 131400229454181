import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image/withIEPolyfill"
/**
 * Internal components
 */
import styles from "../../scss/includes/atoms/product.module.scss"
import PillBottle from "../../images/pill-bottle.svg"
import ParseHTML from "../particles/ParseHTML"
import Button from "./Button"

const Product = ({ product }) => {
  const { title, subtitle, content, button, heading, sets, icon } = product

  const image = () => {
    if (icon) {
      let imageSrc
      if (icon.imageFile.childImageSharp !== null) {
        imageSrc = icon.imageFile.childImageSharp.fluid
      } else {
        imageSrc = {
          src: icon.sourceUrl,
          sizes: icon.sizes,
          srcSet: icon.srcSet,
        }
      }

      return (
        <Img
          className={styles.image}
          objectFit="cover"
          objectPosition="50% 50%"
          alt={icon.altText}
          sizes={{
            ...icon.sizes,
            ...imageSrc,
            aspectRatio: 282 / 282,
            objectFit: "cover",
            objectPosition: "50% 50%",
          }}
        />
      )
    }
  }

  const header = () => {
    if (title || subtitle) {
      return (
        <header className={styles.header}>
          {title && <h2 className={`h2 ${styles.title}`}>{title}</h2>}
          {subtitle && (
            <p className={`p ${styles.subtitle}`}>{ParseHTML(subtitle)}</p>
          )}
          <hr />
        </header>
      )
    }
  }

  const description = () => {
    if (content) {
      return <div className={styles.description}>{ParseHTML(content)}</div>
    }
  }

  const setTypes = () => {
    if (heading || sets) {
      return (
        <div className={styles.types}>
          {heading && <p className={`p ${styles.heading}`}>{heading}</p>}
          {sets && (
            <div className={styles.sets}>
              {sets.map((s, i) => (
                <div key={i} className={styles.set}>
                  {s.title && (
                    <header className={styles.setHeader}>
                      <div className={styles.icon}>
                        <PillBottle />
                      </div>
                      <h5 className={`h5 ${styles.setTitle}`}>
                        {ParseHTML(s.title)}
                      </h5>
                    </header>
                  )}
                  {s.description && (
                    <div className={styles.setDescription}>
                      <p>{s.description}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )
    }
  }

  const footer = () => {
    if (button) {
      return (
        <footer className={`${styles.footer}`}>
          <Button
            className={styles.button}
            title={button.title}
            url={button.url}
            primary
            outline
          />
        </footer>
      )
    }
  }

  return (
    <article
      className={`product ${styles.product}`}
      id={title ? title.toLowerCase().replace(/\s/g, "-") : ""}
      data-sal="slide-up"
      data-sal-easing="ease"
      data-sal-duration="500"
    >
      {icon && (
        <div className={`${styles.column} ${styles.left}`}>
          {image()}
          {button && (
            <Button
              className={styles.button}
              title={button.title}
              url={button.url}
              primary
              outline
            />
          )}
        </div>
      )}

      <div className={`${styles.column} ${styles.right}`}>
        {header()}
        {description()}
        {setTypes()}
        {footer()}
      </div>
    </article>
  )
}

Product.propTypes = {
  product: PropTypes.object,
}

export default Product
