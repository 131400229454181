import PropTypes from "prop-types"
import React from "react"
import Container from "./../organisms/Container"
import Section from "./../organisms/Section"

/**
 * Internal components
 */
import styles from "../../scss/includes/molecules/content-and-icons.module.scss"
import ParseHTML from "../particles/ParseHTML"
import Button from "../atoms/Button"

const ContentAndIcons = ({ content }) => {
  //console.log('ContentAndIcons', content);

  const header = () => {
    if (content.title || content.content || content.button) {
      return (
        <header className={`${styles.header}`}>
          {content.title && <h2 className={`h2`}>{content.title}</h2>}
          {content.content && (
            <div className={`content ${styles.content}`}>
              {ParseHTML(content.content)}
            </div>
          )}
          {content.button && (
            <Button
              className={`${styles.button}`}
              title={content.button.title}
              url={content.button.url}
              target={content.button.target}
              primary
              outline
            />
          )}
        </header>
      )
    }
  }

  const icons = () => {
    if (content.icons) {
      const icons = content.icons

      return (
        <div className={`${styles.icons}`}>
          {icons.map((icon, i) => {
            const image = () => {
              if (icon.image) {
                return (
                  <figure className={styles.figure}>
                    <img
                      className={styles.image}
                      src={icon.image.mediaItemUrl}
                      alt={icon.image.altText}
                    />
                  </figure>
                )
              }
            }

            return (
              <div
                key={i}
                className={styles.icon}
                data-sal="slide-left"
                data-sal-easing="ease"
                data-sal-duration="750"
                data-sal-delay={200 * i}
              >
                {image()}
                {icon.title && <p className={styles.title}>{icon.title}</p>}
              </div>
            )
          })}
        </div>
      )
    }
  }

  return (
    <Section
      className={`content-and-icons ${styles.section} section-space-${content.sectionSpacing}`}
      id={content.anchorId}
    >
      <Container className={`max-width-${content.maxWidth}`}>
        <div className={styles.columns}>
          <div className={styles.left}>{header()}</div>
          <div className={styles.right}>
            {icons()}
            {content.button && (
              <footer className={styles.footer}>
                <Button
                  className={`${styles.button}`}
                  title={content.button.title}
                  url={content.button.url}
                  primary
                  outline
                />
              </footer>
            )}
          </div>
        </div>
      </Container>
    </Section>
  )
}

ContentAndIcons.propTypes = {
  content: PropTypes.object,
}

export default ContentAndIcons
