import PropTypes from "prop-types"
import React from "react"
import { ApolloClient } from "apollo-client"
import { HttpLink } from "apollo-link-http"
import { InMemoryCache } from "apollo-cache-inmemory"
import { ApolloProvider } from "react-apollo"
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks"

/**
 * Internal components
 */
import Post from "./Post"

const client = new ApolloClient({
  link: new HttpLink({
    //uri: process.env.WORDPRESS_GRAPHQL_URL
    uri: "https://console.gammaallergy.com.au/graphql",
  }),
  cache: new InMemoryCache(),
  // for SSR, use:
  // cache: new Cache().restore(window.__APOLLO_STATE__ || {})
})

const Posts = ({ category }) => {
  return (
    <div>
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          <div>
            <Post category={category} />
          </div>
        </ApolloHooksProvider>
      </ApolloProvider>
    </div>
  )
}
Posts.propTypes = {
  category: PropTypes.string.isRequired,
}

export default Posts
