import PropTypes from "prop-types"
import React from "react"
import Container from "./../organisms/Container"
import Section from "./../organisms/Section"

/**
 * Internal components
 */
import styles from "../../scss/includes/molecules/fullwidth-cta.module.scss"
import ParseHTML from "../particles/ParseHTML"
import Button from "../atoms/Button"

const FullWidthCTA = ({ content }) => {
  const header = () => {
    if (content.title || content.content) {
      return (
        <header
          className={`fullwidth-cta__header ${styles.header}`}
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="750"
        >
          {content.title && (
            <h3 className={`fullwidth-cta__title h3`}>{content.title}</h3>
          )}
          {content.content && (
            <div className={`fullwidth-cta__content`}>
              {ParseHTML(content.content)}
            </div>
          )}
        </header>
      )
    }
  }
  const button = content.button
  const footer = () => {
    if (button) {
      return (
        <footer
          className={`fullwidth-cta__footer ${styles.footer}`}
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="750"
          data-sal-delay="200"
        >
          <Button title={button.title} url={button.url} outline />
        </footer>
      )
    }
  }

  //console.log('FullWidthCTA.content', content)

  return (
    <Section
      className={`fullwidth-cta ${styles.section} section-space-${content.sectionSpacing}`}
      id={content.anchorId}
    >
      <Container className={`max-width-${content.maxWidth}`}>
        {header()}
        {footer()}
      </Container>
    </Section>
  )
}

FullWidthCTA.propTypes = {
  content: PropTypes.object,
}

export default FullWidthCTA
