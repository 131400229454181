import { useState, useEffect } from "react"
import useWindowSize from "./useWindowSize"
import useFontsReady from "./useFontsReady"

export default function useSplitLines(content, ref, tag = "p", tagClass = "") {
  const ssr = typeof window === "undefined"
  const { width } = useWindowSize()
  const fontsReady = useFontsReady()
  const [split, setSplit] = useState([content])

  useEffect(() => {
    if (ssr || !ref.current) {
      setSplit([content])
    } else if (content.length === 0) {
      setSplit([])
    } else {
      // Create elem to measure with
      const parent = ref.current
      let elem = document.createElement(tag)
      elem.className = tagClass
      elem.style.display = "block"
      elem.style.width = "100%"
      parent.appendChild(elem)

      // Add words to elem until all used up
      const words = content.split(" ")
      const lines = []
      while (words.length) {
        const line = []
        while (words.length) {
          // Get next word
          const next = words[0]

          // Measure elem before and after adding word
          const hBefore = elem.clientHeight
          elem.innerText = [...line, next].join(" ")
          const hAfter = elem.clientHeight

          // Either end the line, or add the word to it
          if (hBefore > 0 && hAfter > hBefore) break
          else line.push(words.shift())
        }
        lines.push(line.join(" "))
      }
      setSplit(lines)
      parent.removeChild(elem)
      elem = undefined

      return () => {
        if (elem && parent) parent.removeChild(elem)
      }
    }
  }, [ssr, content, ref, tag, tagClass, width, fontsReady, setSplit])

  return split
}
