import PropTypes from "prop-types"
import React from "react"

/**
 * Internal components
 */
import styles from "../../scss/includes/molecules/tabs.module.scss"

const Tabs = ({ children }) => {
  return <div className={`tabs ${styles.tabs}`}>{children}</div>
}

Tabs.propTypes = {
  children: PropTypes.node,
}

export default Tabs
