import PropTypes from "prop-types"
import React from "react"

/**
 * Internal components
 */
import Phone from "../../images/phone.svg"

const FindAllergistsResultsGrid = ({ data, showAll, showFeatured }) => {
  //console.log('FindAllergistsResults', data, showAll)

  const MarkerSvg = (
    <svg viewBox="0 0 40 52">
      <path d="M20,1c5.2,0,10,2.1,13.4,5.6C36.9,10,39,14.8,39,20c0,6.1-1.7,8.6-10.1,20.6c-1.8,2.5-3.8,5.5-6.3,9C22,50.5,21,51,20,51s-2-0.5-2.7-1.4c-2.4-3.5-4.5-6.5-6.3-9C2.7,28.6,1,26.1,1,20c0-5.2,2.1-10,5.6-13.4S14.8,1,20,1z" />
    </svg>
  )
  const MarkerFeaturedSvg = (
    <svg viewBox="0 0 32 31">
      <path d="M8.2,30.4l7.8-4.1l7.8,4.1c1.4,0.7,3-0.4,2.8-2l-1.5-8.7l6.3-6.2c1.1-1.1,0.5-3-1.1-3.3L21.6,9l-3.9-7.9c-0.7-1.4-2.7-1.4-3.4,0L10.4,9l-8.7,1.3c-1.6,0.2-2.2,2.2-1.1,3.3l6.3,6.2l-1.5,8.7C5.1,30,6.8,31.1,8.2,30.4z" />
    </svg>
  )

  const distanceRound = dist => {
    return dist < 20 ? Math.round(dist * 10) / 10 : Math.round(dist)
  }

  const resultRows =
    data &&
    data.map((row, index) => (
      <div
        className={`result-grid-card ${
          showFeatured && row.featured ? "featured" : ""
        }`}
        key={row.id}
      >
        <div className="pin">
          <div
            className={`map-pin ${
              showFeatured && row.featured ? "featured" : ""
            } ${index > 98 ? "big-num" : ""}`}
          >
            {showFeatured && row.featured ? MarkerFeaturedSvg : MarkerSvg}
            <label>{index + 1}</label>
          </div>
        </div>
        <div className="content">
          <div className="name">{row.title}</div>
          {!showAll && (
            <div className="dist">{distanceRound(row.distance)}km away</div>
          )}
          <div className="addr">
            {row.practice && <div className="practice">{row.practice}</div>}
            {row.address1}
            {row.address2 && <div>{row.address2}</div>}
            <div>
              {row.city} {row.state} {row.postcode}
            </div>
          </div>
          <div className="phone">
            {row.phone && (
              <a href="tel:{row.phone}">
                <Phone className="icon" /> {row.phone}
              </a>
            )}
          </div>
        </div>
      </div>
    ))

  return <div class="result-grid-list">{resultRows}</div>
}

FindAllergistsResultsGrid.propTypes = {
  data: PropTypes.array,
  showAll: PropTypes.bool,
  showFeatured: PropTypes.bool,
}

export default FindAllergistsResultsGrid
