import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import Container from "./../organisms/Container"
import Section from "./../organisms/Section"
import GoogleMapReact from "google-map-react"
import { useSiteOptionsData } from "../particles/hooks/useSiteOptionsData"
import FindAllergistsResults from "./../atoms/FindAllergistsResults"
import FindAllergistsResultsGrid from "./../atoms/FindAllergistsResultsGrid"
import MapStyle from "./../atoms/MapStyle"
import Img from "gatsby-image"
import { window } from "browser-monads"
/**
 * Internal components
 */
import styles from "../../scss/includes/molecules/find-allergist.module.scss"
import btnstyles from "../../scss/includes/atoms/button.module.scss"
import { useQueryAllergists } from "../particles/hooks/useQueryAllergists"
import Chevron from "../../images/chevron.svg"
import SearchIcon from "../../images/search-circle.svg"

const FindAllergist = ({ content, location }) => {
  const optionsData = useSiteOptionsData()
  const gmapapikey = optionsData[0].options.google_maps_api_key
  const defaultLat = -25.2744
  const defaultLng = 133.7751

  let data = []

  const [showMap, setMapShow] = useState(false)
  const [canGeoLocate, setCanGeoLocate] = useState(false)
  const [searchMessage, setSearchMessage] = useState("")

  const [gmap, setGmap] = useState({
    ready: false,
    map: null,
    maps: null,
    geocoder: null,
  })

  let state = {
    searchStr: "",
    maxDistance: 800,
    pageSize: 15,
    showBulkBill: false,
    showFeatured: true,
  }

  const [userLoc, setUserLoc] = useState({
    name: "",
    lat: defaultLat,
    lng: defaultLng,
    showAll: true,
    showMarker: false,
  })

  const [popup, setPopUp] = useState({
    open: false,
    lat: defaultLat,
    lng: defaultLng,
    data: {},
  })

  /**
   * Data
   */

  data = useQueryAllergists().map(function (allergist) {
    return {
      ...allergist.acf,
      title: allergist.title,
      id: allergist.databaseId,
      distance: 0,
      marker: null,
      markerListener: null,
    }
  })
  const [resultRows, setResultsRows] = useState(data)

  /**
   * Effects
   */

  // When map ready center and show markers
  useEffect(() => {
    canGetCurrentLocation()
    if (location.state && location.state.cl && canGetCurrentLocation()) {
      getCurrentLocation()
    } else if (location.state && location.state.q && location.state.q !== "") {
      state.searchStr = location.state.q
      document.getElementById("location-input").value = state.searchStr // <- There is a React way to do this. This is not it
      if (gmap.geocoder) {
        geolocate()
      }
    } else {
      centerUserLocation()
    }
    setMapShow(typeof window !== "undefined" && window.innerWidth > 960)
  }, [gmap.ready])

  // When useLoc changes recenter
  useEffect(() => {
    centerUserLocation()
  }, [userLoc])

  // When results change recenter map
  useEffect(() => {
    resetMapBounds()
  }, [resultRows])

  /**
   * Components
   */

  const MarkerSvg = (
    <svg viewBox="0 0 40 52">
      <path d="M20,1c5.2,0,10,2.1,13.4,5.6C36.9,10,39,14.8,39,20c0,6.1-1.7,8.6-10.1,20.6c-1.8,2.5-3.8,5.5-6.3,9C22,50.5,21,51,20,51s-2-0.5-2.7-1.4c-2.4-3.5-4.5-6.5-6.3-9C2.7,28.6,1,26.1,1,20c0-5.2,2.1-10,5.6-13.4S14.8,1,20,1z" />
    </svg>
  )
  const MarkerFeaturedSvg = (
    <svg viewBox="0 0 32 31">
      <path d="M8.2,30.4l7.8-4.1l7.8,4.1c1.4,0.7,3-0.4,2.8-2l-1.5-8.7l6.3-6.2c1.1-1.1,0.5-3-1.1-3.3L21.6,9l-3.9-7.9c-0.7-1.4-2.7-1.4-3.4,0L10.4,9l-8.7,1.3c-1.6,0.2-2.2,2.2-1.1,3.3l6.3,6.2l-1.5,8.7C5.1,30,6.8,31.1,8.2,30.4z" />
    </svg>
  )
  const MarkerWithHoleSvg = (
    <svg viewBox="0 0 40 52">
      <path d="M20,1c5.2,0,10,2.1,13.4,5.6C36.9,10,39,14.8,39,20c0,6.1-1.7,8.6-10.1,20.6c-1.8,2.5-3.8,5.5-6.3,9C22,50.5,21,51,20,51c-1,0-2-0.5-2.7-1.4c-2.4-3.5-4.5-6.5-6.3-9C2.7,28.6,1,26.1,1,20c0-5.2,2.1-10,5.6-13.4S14.8,1,20,1z M20,13.5c-1.8,0-3.4,0.7-4.6,1.9c-1.2,1.2-1.9,2.8-1.9,4.6s0.7,3.4,1.9,4.6c1.2,1.2,2.8,1.9,4.6,1.9c1.8,0,3.4-0.7,4.6-1.9c1.2-1.2,1.9-2.8,1.9-4.6s-0.7-3.4-1.9-4.6C23.4,14.2,21.8,13.5,20,13.5z" />
    </svg>
  )

  const MapMarkers =
    showMap &&
    resultRows &&
    resultRows.map((marker, index) => (
      <div
        className={`map-pin map-marker ${
          state.showFeatured && marker.featured ? "featured" : ""
        } ${index > 98 ? "big-num" : ""}`}
        key={index}
        lat={marker.lat}
        lng={marker.lng}
        marker={marker}
      >
        {state.showFeatured && marker.featured ? MarkerFeaturedSvg : MarkerSvg}
        <label>{index + 1}</label>
      </div>
    ))

  const _onChildClick = (key, childProps) => {
    if (childProps.marker) {
      setPopUp({
        open: true,
        lat: childProps.marker.lat,
        lng: childProps.marker.lng,
        data: childProps.marker,
      })
      let bounds = new gmap.maps.LatLngBounds({
        lat: childProps.marker.lat,
        lng: childProps.marker.lng,
      })
      gmap.map.panToBounds(bounds, {
        top: 250,
        left: 240,
        right: 240,
        bottom: 20,
      })
    }
  }

  const PopupComponent = ({ open, data, showDist, closeFunc }) => (
    <div className={`map-popup ${open ? "open" : ""}`}>
      <div className="map-popup__head">
        <a className="close" onClick={closeFunc}>
          &times;
        </a>
        {data.title && <h2>{data.title}</h2>}
      </div>
      <div className="map-popup__body">
        {data.practice && <div className="practice">{data.practice}</div>}
        <div className="addr">
          {data.address1}
          {data.address2 && (
            <span>
              <br />
              {data.address2}
            </span>
          )}
          , {data.city} {data.state} {data.postcode}
        </div>
        {data.phone && (
          <div className="phone">
            <a href={"tel:" + data.phone}>{data.phone}</a>
          </div>
        )}
        {data.email && (
          <div className="email">
            <a href={"mailto:" + data.email}>{shortenEmail(data.email)}</a>
          </div>
        )}
        {data.web && (
          <div className="web">
            <a href={data.web} target="_blank">
              {shortenUrl(data.web)}
            </a>
          </div>
        )}
        {showDist && data.distance && (
          <div className="distance">{distanceRound(data.distance)}km away</div>
        )}
      </div>
    </div>
  )

  /**
   * Events
   */

  const handleApiLoaded = (m, ms) => {
    setGmap({
      ready: true,
      map: m,
      maps: ms,
      geocoder: new ms.Geocoder(),
    })
  }

  const handleInputChange = event => {
    event.preventDefault()
    state.searchStr = event.target.value
    if (event.which === 13) {
      geolocate()
    }
  }

  const handleInputClick = event => {
    event.preventDefault()
    state.searchStr = document.getElementById("location-input").value
    geolocate()
  }

  const handleCurrentLocaleClick = event => {
    event.preventDefault()
    getCurrentLocation()
  }

  const handleHideMapClick = event => {
    event.preventDefault()
    toggleMap()
  }

  /**
   * Map
   */

  const toggleMap = () => {
    setMapShow(prevState => !prevState)
  }

  /**
   * Search
   */

  const canGetCurrentLocation = () => {
    setCanGeoLocate(
      typeof window !== "undefined" && "geolocation" in window.navigator
    )
    return typeof window !== "undefined" && "geolocation" in window.navigator
  }

  const getCurrentLocation = () => {
    if (canGetCurrentLocation()) {
      navigator.geolocation.getCurrentPosition(function (position) {
        //console.log(position.coords)
        setUserLoc({
          name: "Current Location",
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          showAll: false,
          showMarker: true,
        })
      })
    }
  }

  const geolocate = () => {
    //console.log( 'geolocate', state.searchStr )
    let address = state.searchStr
    let components = { country: "AU" }
    let matches = address.match(/(\d{4,5})/)
    if (matches && matches.length > 1) {
      components.postalCode = matches[1]
      address = address.replace(/(\d{4,5})/, " postcode $1")
    }
    setSearchMessage("")
    closePopup()
    if (address === "") {
      setUserLoc({
        name: "",
        lat: defaultLat,
        lng: defaultLng,
        showAll: true,
        showMarker: false,
      })
      return
    }
    //console.log( 'geolocate', 'address', address, 'componentRestrictions', components )
    gmap.geocoder.geocode(
      { address: address, region: "AU", componentRestrictions: components },
      function (results, status) {
        if (status === "OK") {
          //console.log( 'geolocate results', results[0].geometry.location.lat(), results[0].geometry.location.lng() )
          setUserLoc({
            name: address,
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
            showAll: false,
            showMarker: true,
          })
        } else {
          setSearchMessage(
            "No location found" +
              (status === "ZERO_RESULTS" ? "" : " (" + status + ")")
          )
        }
      }
    )
  }

  const centerUserLocation = () => {
    calcDistances()
    renderResultList()
  }

  /**
   * Markers
   */

  const resetMapBounds = () => {
    //console.log('resetMapBounds', gmap.ready, showMap)
    if (!gmap.ready || !showMap) {
      return
    }
    let bounds = new gmap.maps.LatLngBounds()
    bounds.extend(userLoc)
    resultRows.map(item => bounds.extend({ lat: item.lat, lng: item.lng }))
    gmap.map.fitBounds(bounds, { top: 40, left: 20, right: 20, bottom: 20 })
  }

  const closePopup = () => {
    setPopUp({
      open: false,
      lat: -25.2744,
      lng: 133.7751,
      data: {},
    })
  }

  /**
   * Result list
   */

  const renderResultList = () => {
    let rowArr = []
    let item
    let itemCount = userLoc.showAll
      ? data.length
      : Math.min(data.length, state.pageSize)
    for (let i = 0; i < itemCount; i++) {
      item = data[i]
      if (!userLoc.showAll && item.distance > state.maxDistance) {
        break
      }
      if (state.showBulkBill && !item.bulk_bill) {
        continue
      }
      rowArr.push(item)
    }

    if (state.showFeatured) {
      rowArr.sort(function (a, b) {
        if (a.featured !== b.featured) {
          return a.featured ? -1 : 1
        } else {
          return 0
        }
      })
    }

    setResultsRows(rowArr)
  }

  /**
   * Helpers
   */

  const calcDistances = () => {
    let item
    for (let i = 0; i < data.length; i++) {
      item = data[i]
      if (!item.bulk_bill) {
        item.bulk_bill = false
      }
      data[i].distance = distance(item.lat, item.lng, userLoc.lat, userLoc.lng)
    }
    // Order by distance
    data.sort(function (a, b) {
      return a.distance - b.distance
    })
    if (state.showBulkBill) {
      data.sort(function (a, b) {
        if (a.bulk_bill !== b.bulk_bill) {
          return a.bulk_bill ? -1 : 1
        } else {
          return 0
        }
      })
    }
  }

  const distance = (lat1, lon1, lat2, lon2) => {
    let p = 0.017453292519943295 // Math.PI / 180
    let c = Math.cos
    let a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2
    return 12742 * Math.asin(Math.sqrt(a)) // 2 * R; R = 6371 km
  }

  const distanceRound = dist => {
    if (dist < 20) {
      return Math.round(dist * 10) / 10
    }
    return Math.round(dist)
  }

  const cleanUrl = url => {
    return url.replace(/^http(s)?\:\/\//i, "").replace(/\/$/, "")
  }

  const shortenUrl = url => {
    return cleanUrl(url).length > 36 ? "Visit website" : cleanUrl(url)
  }

  const shortenEmail = email => {
    return email.length > 36 ? "Email" : email
  }

  const absPos = { position: "absolute" }

  const image = () => {
    if (content.image) {
      let imageSrc

      if (content.image.imageFile.childImageSharp !== null) {
        imageSrc = content.image.imageFile.childImageSharp.fluid
      } else {
        imageSrc = {
          src: content.image.sourceUrl,
          sizes: content.image.sizes,
          srcSet: content.image.srcSet,
        }
      }

      return (
        <Img
          className={`find-allergist__image ${styles.image}`}
          alt={content.image.altText}
          sizes={{
            ...content.image.sizes,
            ...imageSrc,
            objectFit: "cover",
            objectPosition: "50% 50%",
          }}
          objectFit="cover"
          objectPosition="50% 50%"
          style={absPos}
        />
      )
    }
  }

  return (
    <Section
      className={`find-allergist ${styles.section} section-space-${content.sectionSpacing}`}
      id={content.anchorId}
    >
      <header className={styles.header}>
        {image()}
        <Container
          className={`${styles.header_container} max-width-${content.maxWidth}`}
        >
          {content.title && <h2 className={styles.title}>{content.title}</h2>}
          <div className={styles.header_finder}>
            <div className={styles.inputWrapper}>
              <input
                type="text"
                id="location-input"
                onKeyUp={event => handleInputChange(event)}
                placeholder="Postcode or Suburb"
              ></input>
              <SearchIcon
                className={styles.inputIcon}
                onClick={handleInputClick}
              />
            </div>
            {canGeoLocate && (
              <a
                href="#"
                onClick={handleCurrentLocaleClick}
                className={`button ${btnstyles.button} ${btnstyles.outline} ${btnstyles.light}`}
              >
                <span className={btnstyles.content}>
                  Use Current Location
                  <span className={`icon ${btnstyles.icon}`}>
                    <Chevron />
                  </span>
                </span>
              </a>
            )}
            {searchMessage && (
              <div className="search-message">{searchMessage}</div>
            )}
          </div>
        </Container>
      </header>

      <div className={styles.body}>
        <Container className={`max-width-${content.maxWidth}`}>
          <div className={styles.head}>
            <span>
              Allergists {userLoc.name !== "" ? "near " + userLoc.name : ""}
            </span>
            <a href="#" onClick={handleHideMapClick}>
              {showMap ? "Hide" : "Show"} Map
            </a>
          </div>

          <div className={`${styles.map} ${showMap ? "show" : "hide"}`}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: gmapapikey }}
              defaultCenter={{ lat: defaultLat, lng: defaultLng }}
              defaultZoom={4}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              onChildClick={_onChildClick}
              options={{
                disableDefaultUI: true,
                zoomControl: true,
                scrollwheel: false,
                styles: MapStyle(),
              }}
            >
              {showMap && userLoc.showMarker && (
                <div
                  className="map-pin map-marker-user"
                  lat={userLoc.lat}
                  lng={userLoc.lng}
                >
                  {MarkerWithHoleSvg}
                </div>
              )}
              {MapMarkers}
              <PopupComponent
                open={popup.open}
                lat={popup.lat}
                lng={popup.lng}
                data={popup.data}
                showDist={!userLoc.showAll}
                closeFunc={closePopup}
              />
            </GoogleMapReact>
            {MapStyle}
          </div>

          <div className={styles.resultsGrid}>
            <FindAllergistsResultsGrid
              data={resultRows}
              showAll={userLoc.showAll}
              showFeatured={state.showFeatured}
            />
          </div>
        </Container>
      </div>
    </Section>
  )
}

FindAllergist.propTypes = {
  content: PropTypes.object,
}

export default FindAllergist
