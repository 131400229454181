import PropTypes from "prop-types"
import React from "react"
import Container from "./../organisms/Container"
import Section from "./../organisms/Section"

/**
 * Internal components
 */
import styles from "../../scss/includes/molecules/fullwidth-content.module.scss"
import ParseHTML from "../particles/ParseHTML"

const FullWidthContent = ({ content }) => {
  // console.log("FullWidthContent.content", content)

  const width =
    content.maxWidth === "l"
      ? `max-width-l margin-horizontal-auto ${styles.width}`
      : ""

  return (
    <Section
      className={`fullwidth-content ${styles.section} ${width} section-space-${content.sectionSpacing}`}
      id={content.anchorId}
    >
      <Container className={`max-width-${content.maxWidth}`}>
        <div className={styles.contentWrapper}>
          {content.title && (
            <h2 className={`h2 ${styles.title}`}>{content.title}</h2>
          )}
          {content.content && (
            <div className="content">{ParseHTML(content.content)}</div>
          )}
        </div>
      </Container>
    </Section>
  )
}

FullWidthContent.propTypes = {
  content: PropTypes.object,
}

export default FullWidthContent
